import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BuildingImage from '../assets/images/svg/mags-building.svg'
import Layout from '../components/layout'
import {
  buttonHoverStyles,
  buttonStyles,
  plantinBoldItalic,
  plantinItalic,
} from '../styles'

const FourOhWrap = styled.div({
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

const FourOhHeader = styled.h1({
  fontFamily: plantinBoldItalic,
  letterSpacing: -0.5,
  fontSize: '4.4rem',
  marginBottom: '0',
})

const FourOhCopy = styled.p({
  fontFamily: plantinItalic,
  letterSpacing: -0.5,
  fontSize: '2.8rem',
  margin: '1rem 0 3rem 0',
})

const styles = {
  stroke: 'red',
  fill: 'red',
  width: '100%',
  maxWidth: '100%',
}

const ImageWrapper = styled.div({
  marginBottom: '3rem',
  maxWidth: '100%',
  padding: '0 2rem',
  '@media screen and (min-width: 720px)': {
    maxWidth: '50%',
  },
})

const ReturnLink = styled.div({
  margin: '2rem 0',
})

const MLink = styled(Link)`
  ${buttonStyles}
  ${buttonHoverStyles}
`

const NotFoundPage = () => (
  <Layout>
    <FourOhWrap>
      <ImageWrapper>
        <BuildingImage style={styles} />
      </ImageWrapper>
      <FourOhHeader>Sorry!</FourOhHeader>
      <FourOhCopy> This page appears to have gone missing!</FourOhCopy>
      <ReturnLink>
        <MLink
          to="/">
          Return to our home page
        </MLink>
      </ReturnLink>
    </FourOhWrap>
  </Layout>
)

export default NotFoundPage
